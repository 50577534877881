//  Primary
$primary-main: #1D8ED0 !default;
$primary-alternative-light: #4FC0FF !default;
$primary-alternative-dark: #083761 !default;
$primary-contained-hover-background: #00A1FF !default;
$primary-outlined-hover-background: rgba(29, 142, 208, 0.08) !default;
$primary-outlined-resting-background: rgba(29, 142, 208, 0.5) !default;

//Secondary
$secondary-main: #8A8D93 !default;
$secondary-alternative-light: #9C9FA4 !default;
$secondary-alternative-dark-2: #6F767E !default;
$secondary-alternative-dark: #4D5056 !default;
$secondary-contained-hover-background: #777B82 !default;
$secondary-outlined-hover-background: rgba(138, 141, 147, 0.08) !default;
$secondary-outlined-resting-background: rgba(138, 141, 147, 0.5) !default;
$secondary-alternative-lights: #3A3541 !default;
$secondary-alternative-lights-shadow: #3A35411A !default;
$secondary-box-shadow: #00000040 !default;
$secondary-alternative-box-shadow: rgba(58, 53, 65, 0.42) !default;

//Info
$info-main: #16B1FF !default;
$info-alternative-light: #32BAFF !default;
$info-alternative-dark: #0E71A3 !default;
$info-contained-hover-background: #139CE0 !default;
$info-outlined-hover-background: rgba(50, 186, 255, 0.08) !default;
$info-outlined-resting-background: rgba(50, 186, 255, 0.5) !default;

//Success
$success-main: #56CA00 !default;
$success-alternative-light: #6AD01F !default;
$success-alternative-dark: #378100 !default;
$success-contained-hover-background: #4CB200 !default;
$success-outlined-hover-background: rgba(86, 202, 0, 0.08) !default;
$success-outlined-resting-background: rgba(86, 202, 0, 0.5) !default;

//Warning
$warning-main: #FFB400 !default;
$warning-alternative-light: #FFB547 !default;
$warning-alternative-dark: #A37300 !default;
$warning-contained-hover-background: #E09E00 !default;
$warning-outlined-hover-background: rgba(255, 180, 0, 0.08) !default;
$warning-outlined-resting-background: rgba(255, 180, 0, 0.5) !default;

//Error
$error-default: #FA0B0B !default;
$error-main: #FF4C51 !default;
$error-alternative-light: #FF6166 !default;
$error-alternative-dark: #A33134 !default;
$error-contained-hover-background: #E04347 !default;
$error-outlined-hover-background: rgba(255, 76, 81, 0.08) !default;
$error-outlined-resting-background: rgba(255, 76, 81, 0.5) !default;
//báo giá
$purple-default: #c111e4 !default;
$purple-outlined-hover-background: rgba(218, 170, 230, 0.185) !default;

//new
$greenness-default: #068992 !default;
$greenness-outlined-hover-background: rgba(142, 235, 230, 0.185) !default;
$primary-outlined-background: #F5FCFF !default;
$border-alternative-light: #E0E0E0 !default;

//Text
$text-primary: rgba(58, 53, 65, 0.87) !default;
$text-secondary: rgba(58, 53, 65, 0.68) !default;
$text-disabled: rgba(58, 53, 65, 0.38) !default;

//Custom BG
$custom-bg-primary: linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #1D8ED0;
$custom-bg-secondary: linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #8A8D93;
$custom-bg-info: linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #2196F3;

$background: #F1F1F2 !default;
$neutral-3: #A7B0BA !default;
$neutral-4: #EBEBEC !default;
$white: #FFF !default;
$error-main: #FF4C51;
$bg-2: #E8EAF6;
$background-grid: #FAFBFC !default;
$grayscale: #6E7191 !default;
$action-active: rgba(58, 53, 65, 0.54) !default;
$action-hover: rgba(58, 53, 65, 0.04) !default;

// Custom
$transparent: transparent !default;
$action-disabled: rgba(58, 53, 65, 0.26);
$background-white: #ffffff;
$overlay-background: rgba(0, 0, 0, 0.7)

//$primary-0: #141ED2 !default;
//$primary-1: #0A73F0 !default;
//$primary-2: #00B4FA !default;
//$primary-3: #9DC7F9 !default;
//$primary-4: #E7F1FD !default;
//$primary-5: #F3F8FE !default;
//$secondary-1: #0B7CC3 !default;
//$secondary-2: #21ADE5 !default;
//$neutral-1: #031D3B !default;
//$neutral-2: #4F6176 !default;
//$neutral-3: #A7B0BA !default;
//$neutral-4: #E6E8EB !default;
//$neutral-5: #F2F4F5 !default;
//$red-1: #FD4A1E !default;
//$red-2: #FE8061 !default;
//$red-3: #FEA48E !default;
//$red-4: #FFD9D0 !default;
//$red-4: #FFF6F4 !default;
//$green-1: #0DB55A !default;
//$green-2: #ADE890 !default;
//$green-3: #D6F3C7 !default;
//$green-4: #E7F8EE !default;
//$green-5: #F3FBF7 !default;
//$orange-1: #FF9A3C !default;
//$orange-2: #FFB877 !default;
//$orange-3: #FFDCBB !default;
//$orange-4: #FFF5EB !default;
//$orange-5: #FFFAF5 !default;
//$purple-1: #989AFB !default;
//$purple-2: #B7B8FC !default;
//$purple-3: #DBDCFE !default;
//$purple-4: #F5F5FF !default;
//$purple-5: #FAFAFF !default;
//$bg-1: #E8F3FF !default;
//$bg-2: #C8D8FF !default;
//$bg-3: #8DA6FF !default;
//$shadow-1: 1px 4px 6px rgba(26, 28, 38, 0.1);
//$shadow-2: 1px 4px 12px rgba(26, 28, 38, 0.2);
//$shadow-3: #b0b0b0;
//
//$highlight: #0A73F0;
//
//// stylelint-disable
//$white: #fff !default;
//$gray-100: #eff2fe !default;
//$gray-200: #fbfbfd !default;
//$gray-300: #f0f0f3 !default;
//$gray-400: #ced4da !default;
//$gray-500: #e1e4ea !default;
//$gray-600: #505867 !default;
//$gray-700: #ececf2 !default;
//$gray-800: #f5f5fa !default;
//$gray-900: #8181a5 !default;
//$black: #000 !default;
//
//$grays: () !default;
//$grays: map-merge(
//    (
//      '100': $gray-100,
//      '200': $gray-200,
//      '300': $gray-300,
//      '400': $gray-400,
//      '500': $gray-500,
//      '600': $gray-600,
//      '700': $gray-700,
//      '800': $gray-800,
//      '900': $gray-900,
//    ),
//    $grays
//);
//
//$blue: #00479d !default;
//$indigo: #6610f2 !default;
//$purple: #9698d6 !default;
//$pink: #f5a0ba !default;
//$red: #ff808b !default;
//$orange: #fd7e14 !default;
//$yellow: #f4be5e !default;
//$green: #58cf8d !default;
//$teal: #2ce5f6 !default;
//$cyan: #17a2b8 !default;
//$danger: #ff0000 !default;
//
//$colors: () !default;
//$colors: map-merge(
//    (
//      'blue': $blue,
//      'indigo': $indigo,
//      'purple': $purple,
//      'pink': $pink,
//      'red': $red,
//      'orange': $orange,
//      'yellow': $yellow,
//      'green': $green,
//      'teal': $teal,
//      'cyan': $cyan,
//      'white': $white,
//      'gray': $gray-600,
//      'gray-dark': $gray-800,
//    ),
//    $colors
//);
//
//$primary: $blue !default;
//$primary1: #0A73F0 !default;
//$primary2: #0B7CC3 !default;
//$primary4: #e5edf5 !default;
//$primary5: #f2f6fa !default;
//
//$secondary: white !default;
//$secondary1: #F3F8FE !default;
//$success: $green !default;
//$info: $cyan !default;
//$warning: $yellow !default;
//$light: $gray-100 !default;
//$dark: $gray-800 !default;
